<template>
  <div class="video-list">
    <van-card v-for="item in list"
              :key="item.id"
              :title="item.title"
              :thumb="item.titlepic||item.cover"
              @click="showInfo(item)">
      <div slot="num">
        {{item.length}}
      </div>
      <div slot="price">
        {{item.lastdotime}}
      </div>
    </van-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { Card, Tag } from 'vant';
Vue.use(Card).use(Tag);

export default {
  props: ["list"],
  methods: {
    openVideo (item) {
      this.$router.push({ name: 'play', query: { id: item.id, classid: item.classid } });
    }
  }
}
</script>

<style scoped>
.video-list .van-card__thumb {
  width: 80px;
  height: 60px;
}
.video-list .van-card__content {
  min-height: 60px;
}
</style>