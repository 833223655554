<template>
  <div>
    <van-cell-group class="mv-15" v-if="id">
      <van-cell :title="infor?infor.title:'资料名称'" @click="showInfo(infor)" is-link label="点击查看本套资料详情">
        <template #icon>
          <img :src="infor?infor.titlepic:''" style="width: 60px; height: 60px; margin-right: 10px" />
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group>
      <van-cell icon="video" title="视频列表"></van-cell>
      <van-skeleton :loading="!isReady" :row="20" title>
        <van-empty description="记录为空" v-if="!list.length" />
        <video-grid :list="list" v-else />
      </van-skeleton>
    </van-cell-group>
    <div class="mv-10" v-if="isReady">
      <van-pagination :items-per-page="size" :total-items="total" :value="page" @change="pageChanged" mode="simple" />
    </div>
  </div>
</template>

<script>
import { listMixin } from "@/libs/util";
import Vue from "vue";
import { Pagination, CellGroup } from "vant";
Vue.use(Pagination).use(CellGroup);
import videoGrid from "../components/videoGrid";

export default {
  name: "videoIndex",
  components: { videoGrid },
  mixins: [listMixin],
  data() {
    return {
      isReady: false,
      infor: null,
      list: [],
      size: 20,
      total: 0,
    };
  },
  computed: {
    id() {
      return this.$route.params.id * 1 || 0;
    },
    page() {
      return this.$route.params.p * 1 || 1;
    },
    changeParam() {
      const param = this.$route.params;
      return param.id + "-" + param.p;
    },
  },
  watch: {
    changeParam: {
      handler() {
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    getList() {
      const param = {
        shopid: this.id,
        size: this.size,
        page: this.page,
      };
      this.$http.get("e/action/VideoShowInfo.php", param).then((res) => {
        if (res.code === 0) {
          this.isReady = true;
          this.setData(res.data);
        }
      });
    },
    pageChanged(p) {
      this.$router.push({ name: "videoList", params: { id: this.id, p } });
    },
  },
};
</script>
